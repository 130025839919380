var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "logorderdetail-container position-relative" },
    [
      _c("div", { staticClass: "auto-table-flex" }, [
        _c("div", { staticClass: "text-center sale-font mb-25" }, [
          _vm._v(" 订单号：" + _vm._s(_vm.merchantList.order_no) + " "),
        ]),
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                ref: "addForm",
                attrs: {
                  "label-width": "150px",
                  model: _vm.merchantList,
                  inline: true,
                  "hide-required-asterisk": true,
                },
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "客户姓名", prop: "title" } },
                              [
                                _vm.merchantList.member
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.merchantList.member.fullname
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "客户电话", prop: "storeType" },
                              },
                              [
                                _vm.merchantList.member
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.merchantList.member.mobile
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "城市仓", prop: "warehouseId" },
                              },
                              [
                                _vm.merchantList.store_delivery &&
                                _vm.merchantList.store_delivery.logistics
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.merchantList.store_delivery
                                              .logistics.name
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "自提点信息",
                                  prop: "carLicense",
                                },
                              },
                              [
                                _vm.merchantList.store_delivery
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.merchantList.store_delivery.name
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mb-30" },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            ref: "goodsTable",
                            attrs: {
                              data: _vm.goodsData,
                              border: true,
                              "show-summary": true,
                              "summary-method": _vm.goodsCount,
                              "header-cell-style": {
                                color: "#333333",
                                background: "#EFF6FF",
                                "text-align": "center",
                              },
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                width: "80",
                                align: "center",
                                label: "序号",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "goodsName",
                                align: "center",
                                label: "主图",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return scope.row.product
                                        ? [
                                            _c("el-image", {
                                              staticClass: "kv pointer",
                                              attrs: {
                                                src: scope.row.product.kv,
                                                "preview-src-list": [
                                                  scope.row.product.kv,
                                                ],
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        : undefined
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "title",
                                align: "left",
                                width: "250",
                                label: "商品信息",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.product
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "mb-15",
                                                staticStyle: {
                                                  "font-weight": "bold",
                                                },
                                              },
                                              [
                                                scope.row.product.title
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.product
                                                            .title
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                scope.row.product.spec
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "#" +
                                                          _vm._s(
                                                            scope.row.product
                                                              .spec
                                                          )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                scope.row.product.sku_code
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "(" +
                                                          _vm._s(
                                                            scope.row.product
                                                              .sku_code
                                                          ) +
                                                          ")"
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "mb text-light" },
                                              [
                                                _vm._v(
                                                  " 等级/规格：" +
                                                    _vm._s(
                                                      _vm.$empty.empty(
                                                        scope.row.product.grade
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                scope.row.product.unit_type == 1
                                                  ? _c("span", [_vm._v("/件")])
                                                  : _vm._e(),
                                                scope.row.product.unit_type == 2
                                                  ? _c("span", [_vm._v("/箱")])
                                                  : _vm._e(),
                                                scope.row.product.unit_type == 3
                                                  ? _c("span", [
                                                      _vm._v("/袋子"),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c("div", { staticClass: "mb" }, [
                                              _vm._v(
                                                " 毛重/毛单价：" +
                                                  _vm._s(
                                                    _vm.$empty.empty(
                                                      scope.row.product
                                                        .gross_weight
                                                    )
                                                  ) +
                                                  "【" +
                                                  _vm._s(
                                                    _vm.tool.toDecimal2(
                                                      (((scope.row.product
                                                        .price *
                                                        1000) /
                                                        scope.row.product
                                                          .gross_weight) *
                                                        1000) /
                                                        100 /
                                                        1000000
                                                    )
                                                  ) +
                                                  "元/斤】 "
                                              ),
                                            ]),
                                            _c(
                                              "div",
                                              { staticClass: "mb-15" },
                                              [
                                                _vm._v(
                                                  " 净重/净单价：" +
                                                    _vm._s(
                                                      _vm.$empty.empty(
                                                        scope.row.product.weight
                                                      )
                                                    ) +
                                                    "【" +
                                                    _vm._s(
                                                      _vm.tool.toDecimal2(
                                                        (((scope.row.product
                                                          .price *
                                                          1000) /
                                                          scope.row.product
                                                            .weight) *
                                                          1000) /
                                                          100 /
                                                          1000000
                                                      )
                                                    ) +
                                                    "元/斤】 "
                                                ),
                                              ]
                                            ),
                                            _c("div", [
                                              scope.row.product.supplier ||
                                              scope.row.product.buyer
                                                ? _c("div", [
                                                    _c("span", [
                                                      _vm._v(
                                                        "主供货商：" +
                                                          _vm._s(
                                                            scope.row.product
                                                              .supplier.fullname
                                                          )
                                                      ),
                                                    ]),
                                                    scope.row.product.buyer
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "(" +
                                                              _vm._s(
                                                                scope.row
                                                                  .product.buyer
                                                                  .fullname
                                                              ) +
                                                              ")"
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ])
                                                : _c("div", [_vm._v("—")]),
                                            ]),
                                          ])
                                        : _c("div", [_vm._v("—")]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "qty",
                                align: "center",
                                label: "采购数量",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$empty.empty(scope.row.qty)
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "price",
                                align: "center",
                                label: "开市价(元)",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.tool.toDecimal2(
                                              (scope.row.price * 10000) /
                                                100 /
                                                10000
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "product_amt",
                                align: "center",
                                label: "商品总额",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.tool.toDecimal2(
                                              (scope.row.product_amt * 10000) /
                                                100 /
                                                10000
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "purchase_amt",
                                align: "center",
                                label: "服务费(元)",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.tool.toDecimal2(
                                              (scope.row.purchase_amt * 10000) /
                                                100 /
                                                10000
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "freight_amt",
                                align: "center",
                                label: "运费(元)",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.tool.toDecimal2(
                                              (scope.row.freight_amt * 10000) /
                                                100 /
                                                10000
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "finance_amt",
                                align: "center",
                                label: "金融手续费(元)",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.tool.toDecimal2(
                                              (scope.row.finance_amt * 10000) /
                                                100 /
                                                10000
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "actual_payment",
                                align: "center",
                                label: "总金额(元)",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.tool.toDecimal2(
                                              (((scope.row.product_amt *
                                                10000) /
                                                100 /
                                                10000) *
                                                10000 +
                                                ((scope.row.purchase_amt *
                                                  10000) /
                                                  100 /
                                                  10000) *
                                                  10000 +
                                                ((scope.row.freight_amt *
                                                  10000) /
                                                  100 /
                                                  10000) *
                                                  10000 +
                                                ((scope.row.finance_amt *
                                                  10000) /
                                                  100 /
                                                  10000) *
                                                  10000) /
                                                10000
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "订单创建时间",
                                  prop: "create_time",
                                },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$empty.empty(
                                        _vm.merchantList.create_time
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "订单支付时间",
                                  prop: "spay_attoreType",
                                },
                              },
                              [
                                _vm.merchantList.pay_at
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$empty.time(
                                              _vm.merchantList.pay_at
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("div", [_vm._v("—")]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "订单取消时间",
                                  prop: "cancel_at",
                                },
                              },
                              [
                                _vm.merchantList.cancel_at
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$empty.time(
                                              _vm.merchantList.cancel_at
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("div", [_vm._v("—")]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "订单完成时间",
                                  prop: "receive_at",
                                },
                              },
                              [
                                _vm.merchantList.receive_at
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$empty.time(
                                              _vm.merchantList.receive_at
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("div", [_vm._v("—")]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "fy1" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$router.back()
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showImage,
            expression: "showImage",
          },
        ],
        staticClass: "shadow",
        on: {
          click: function ($event) {
            _vm.showImage = false
          },
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showImage,
              expression: "showImage",
            },
          ],
          staticClass: "bigImage",
        },
        [
          _c("img", { attrs: { src: _vm.bigImage, alt: "" } }),
          _c("i", {
            staticClass: "el-icon el-icon-close bigClose",
            on: {
              click: function ($event) {
                _vm.showImage = false
              },
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }